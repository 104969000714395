@media (max-width: 992px) {
  .btn-mobil {
    top: 9px;
  }

  .hero {
    padding-top: 150px;
  }

  .mt150 {
    margin-top: 80px;
  }

  .mb150 {
    margin-bottom: 80px;
  }

  .home-text {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .home-text__article {
    padding-left: 0;
  }

  .story__content {
    padding: 15px 15px 60px;
  }

  .story__arrow {
    padding: 15px;
  }

  .story__title {
    font-size: 1.1rem;
  }

  .custom-button--big {
    width: 45px;
    height: 45px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .mt100 {
    margin-top: 70px;
  }

  .hero__img {
    position: relative;
    right: auto;
    left: auto;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 320px;
    text-align: center;

    img {
      position: relative;
      right: -7%;
      bottom: -40px;
    }
  }

  .hero__content {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }

  .hero__text {
    padding-right: 0;
  }

  .hero {
    height: auto;
  }

  .social__link svg {
    width: 20px;
    height: 20px;
  }

  .footer {
    padding-top: 50px;
    padding-bottom: 15px;
  }

  .footer__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .social__link {
    margin-right: 10px;
  }

  .footer-content {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;

    .flex {
      margin-right: auto;
      margin-left: auto;
      width: 280px;
    }
  }

  .newsletter-title .text {
    padding: 0;
  }

  .number-info__content{
    margin-top: 30px;
  }

  .number-info__number{
    top: -15px;
    left: 15px;
  }

  .number-info__row{
    margin-bottom: 50px;
  }

  .info-card{
    padding: 15px;
  }

  .timeline__item--2{
    top: 12%;
    left: 26%;
  }

  .step-card__title{
    padding: 8px;
  }

  .step-card__number{
    width: 36px;
    height: 36px;
    font-size: 1.1rem;
  }

  .steps-wrapper__line{
    top: 27px;
  }

  .road__item{
    &--1 {
      top: 12%;
      left:4%;
    }

    &--2 {
      top: 34%;
      right: 4%;
    }

    &--3 {
      top: 58%;
      left: 4%;
    }

    &--4 {
      bottom: 10%;
      right: 4%;
    }
  }

  .banner--content{
    padding-top: 30px;
  }

  .whats-next-wrapper .text{
    padding: 0;
  }

  .whats-next{
    flex-wrap: wrap;
  }

  .whats-next{
    &__item{
      margin-bottom: 30px;
      width: calc(50% - 30px);
    }
  }

  .whats-next__divider{
    margin-bottom: 30px;
    &:nth-of-type(2){
      display: none;
    }
  }

  .service-tabs .home-text__article{
    padding-right: 0;
  }

  .banner-left-text{
    width: calc(100% - 180px);
  }

  .banner-left-img{
    width: 160px;
  }
}
