@media (max-width: 576px) {
  html {
    font-size: 75%;
  }

  .wrapper {
    padding: 0;
  }

  .header {
    width: 100%;

    &--page {
      margin: 0;
      border-radius: 0;
    }
  }

  .hero__title {
    font-size: 5vw;
  }

  .hero__content {
    padding: 15px;
  }

  .mt150 {
    margin-top: 45px;
  }

  .mb150 {
    margin-bottom: 45px;
  }

  .container-left {
    padding-left: 15px !important;
    width: 100%;
  }

  .footer {
    padding-top: 30px;
    background-position: bottom right;
    background-size: cover;
  }

  .footer-menu {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;

    &__title {
      margin-bottom: 15px;
    }
  }

  .newsletter__btn {
    width: 100px;
    height: 40px;
    font-size: 0.8rem;
    line-height: 38px;
  }

  .newsletter__input {
    width: calc(100% - 110px);
    height: 40px;
    line-height: 40px;
  }

  .score-form__input{
    width: 48%;
    margin-bottom: 4%;
    height: 40px;
    line-height: 40px;
  }

  .score-form__btn{
    width: 100%;
    height: 40px;
    line-height: 38px;
  }

  .home-newsletter {
    padding: 30px 0;
  }

  .footer-content {
    .flex {
      width: 230px;
    }
  }

  .copyright {
    justify-content: center;
  }

  .copyright__text {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .hero__img img {
    right: 0;
  }

  .search__inputs {
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
  }

  .search__input {
    text-align: center;
  }

  .search__btn {
    width: 100%;
  }

  .search__divider {
    height: 28px;
  }

  .search {
    bottom: -100px;
  }

  .number-info__article {
    padding-right: 0;
  }

  .page-contact {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .step-card {
    width: 46%;
  }

  .road__line {
    width: 280px;
    opacity: 0.1;
  }

  .road__number {
    img {
      display: none;
    }
  }

  body {
    counter-reset: my-sec-counter;
  }

  .road__item {
    width: 200px;
    border-radius: 10px;

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: auto;
      margin-bottom: 6px;
      margin-left: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $renk_turuncu;
      color: #fff;
      content: counter(my-sec-counter);
      counter-increment: my-sec-counter 1;
      font-weight: 700;
    }

    &--1 {
      top: 6%;
      left: 0;
    }

    &--2 {
      top: 30%;
      right: 0;
    }

    &--3 {
      top: 54%;
      left: 0;
    }

    &--4 {
      right: 0;
      bottom: 6%;
    }
  }

  .road__title {
    font-size: 1.1rem;
  }

  .network__icon {
    margin-bottom: 15px;
    width: 70px;
    height: 70px;
  }

  .content-page {
    padding: 0;
  }

  .banner--content {
    padding-bottom: 180px;
  }

  .content-share {
    position: relative;
    top: auto;
    left: auto;
    width: auto;

    a {
      display: inline-block;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .other-content {
    margin-right: auto;
    margin-left: auto;
    max-width: calc(100% - 30px);
  }

  .blog-box {
    padding: 15px;

    &__img {
      margin: -15px -15px 0;
    }
  }

  .blog-box__title {
    margin-top: 15px;
    min-height: 72px;
  }

  .writer__img{
    width: 40px;
    height: 40px;
  }

  .writer__content{
    width: calc(100% - 50px);
    padding-left: 10px;
  }

  .writer--small{
    .writer__img{
      width: 30px;
      height: 30px;
    }

    .writer__left{
      width: calc(100% - 90px);
      margin-bottom: 0;
    }

    .writer__time{
      width: 86px;
      text-align: right;
    }

    .writer__content{
      width: calc(100% - 40px);
      padding-left: 8px;
    }
  }

  .alg-timeline__arrow{
    width: 40px;
    left: -44px;
    top: -2px;
  }

  .alg-timeline__item:before{
    width: 10px;
    height: 10px;
    left: -44px;
  }

  .alg-timeline{
    padding-left: 40px;
  }

  .container-banner{
    padding-left: 15px;
    padding-right: 15px;
  }

  .no-number{
    .number-info__article{
      padding-left: 0;
    }
  }

  .team__bottom{
    padding: 15px 5px;
  }

  .whats-next-wrapper{
    padding: 30px 15px;
    border-radius: 0;
  }

  .whats-next__item{
    padding: 15px;
  }

  .contact__title{
    font-size: 1.2rem;
  }

  .banner-left-text{
    width: calc(100% - 110px);
  }

  .banner-left-img{
    width: 100px;
  }
}
