@media (max-width:1600px) {
  html {
    font-size: 95%;
  }

  .btn {
    padding: 0.475rem 1.4rem;
  }

  .menu__item {
    margin-left: 20px;
  }

  .header__buttons {
    margin-left: 20px;
  }

  .social__link svg {
    width: 30px;
    height: 30px;
  }
}
