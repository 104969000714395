.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.675rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  svg{
    position: relative;
    top: -1px;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn--arrow{
  svg{
    transition: all .3s;
  }
  &:hover{
    svg{
      margin-left: 15px !important;
    }
  }
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
}

.btn-primary {
  color: #fff;
  background-color: $renk_turuncu;
  border-color: $renk_turuncu;

  &:hover {
    color: #fff;
    background-color: $renk_turuncu_dk;
    border-color: $renk_turuncu_dk;
  }
}

.btn-outline-primary {
  color: $renk_turuncu;
  border-color: $renk_turuncu;

  &:hover {
    color: #fff;
    background-color: $renk_turuncu;
    border-color: $renk_turuncu;
  }
}

.btn-outline-secondary {
  color: #000;
  border-color: #000;

  &:hover {
    color: #fff;
    background-color: $renk_turuncu;
    border-color: $renk_turuncu;
  }
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;

  &:hover {
    color: #fff;
    background-color: $renk_turuncu_dk;
    border-color: $renk_turuncu_dk;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  font-size: 1.1rem;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.9rem;
}
