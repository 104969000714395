@media (max-width:1200px) {
  html {
    font-size: 85%;
  }

  .menu {
    display: none;
  }

  .btn-mobil {
    position: relative;
    top: 7px;
    display: inline-block;
    margin-left: 0.5rem;
    padding: 0;
    outline: transparent;
  }

  .hero__text {
    padding-right: 15%;
    font-size: 1.2rem;
  }

  .hero {
    padding-top: 200px;
  }

  .text {
    &--big {
      font-size: 1.1rem;
    }

    &--bigger {
      font-size: 1.2rem;
    }
  }

  .mt150 {
    margin-top: 100px;
  }

  .mb150 {
    margin-bottom: 100px;
  }

  .flex-title__text {
    width: calc(100% - 270px);
  }

  .service__icon {
    margin-bottom: 30px;
    width: 48px;
    height: 48px;
  }

  .home-text {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .header__right {
    position: relative;
    top: -2px;
  }

  .footer {
    padding-top: 120px;
    padding-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .footer__bottom {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .number-info__number {
    height: 90px;
  }

  .timeline {
    height: 360px;
  }

  .timeline__icon {
    width: 48px;
  }

  .timeline__item--3 {
    right: 26%;
  }

  .timeline__item--4 {
    right: 0;
  }

  .contact-form {
    padding: 30px;
  }

  .road__item {
    &--1 {
      top: 12%;
      left: 16%;
    }

    &--2 {
      top: 34%;
      right: 16%;
    }

    &--3 {
      top: 58%;
      left: 16%;
    }

    &--4 {
      bottom: 10%;
      right: 16%;
    }
  }

  .road__item {
    width: 470px;
  }

  .road__number {
    width: 200px;
  }

  .network__line {
    width: 230px;
  }

  .network__item--2,
  .network__item--3 {
    margin-top: 140px;
  }

  .network__line--middle {
    bottom: 48%;
  }

  .about-number__number {
    font-size: 4rem;
    line-height: 1.4;
  }

  .whats-next-wrapper{
    padding: 50px;
  }

  .whats-next__divider {
    width: 30px;
  }

  .whats-next__item{
    padding: 15px;
  }
}
