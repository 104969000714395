@media (max-width: 480px) {
  .header {
    height: 56px;

    .btn-outline-primary {
      display: none;
    }

    &__buttons {
      margin-left: 0;
    }
  }

  .footer-logo,
  .logo {
    width: 120px;
  }

  .whats-next__divider{
    display: none;
  }

  .whats-next__item{
    width: 50%;
  }
}
