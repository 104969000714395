@media (max-width: 768px) {
  html {
    font-size: 80%;
  }

  .underline {
    &:after {
      bottom: -3px;
      height: 2px;
    }
  }

  .hero {
    padding-top: 120px;
  }

  .hero__title {
    font-size: 4vw;
  }

  .hero__content {
    width: 100%;
  }

  .hero__text {
    margin-bottom: 15px;
  }

  .slider-title {
    &__text {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .mt150 {
    margin-top: 60px;
  }

  .mb150 {
    margin-bottom: 60px;
  }

  .flex-title__text {
    margin-bottom: 10px;
    width: 100%;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .service {
    padding: 15px;

    &__icon {
      width: 36px;
      height: 36px;
    }

    &__title {
      font-size: 1.3rem;
    }
  }

  .comment {
    padding: 15px;
  }

  .comments-slider {
    margin-left: -15px;
    padding: 15px 0 0 15px;
  }

  .mt100 {
    margin-top: 30px;
  }

  .home-faq {
    .mb30 {
      margin-bottom: 5px;
    }
  }

  .hero__img {
    margin-top: -40px;
  }

  .footer__bottom {
    justify-content: center;
    padding-top: 0;
  }

  .social {
    justify-content: center;
    margin-bottom: 15px;

    &__link {
      margin: 4px;
    }
  }

  .footer-links {
    width: 100%;
  }

  .footer-links__list {
    justify-content: center;
    text-align: center;
  }

  .footer-links__item {
    margin: 4px;
  }

  .minisoft {
    width: 90px;
  }

  .home-newsletter {
    padding: 30px 0;
  }

  .search__btn {
    width: 100px;
    height: 36px;
    line-height: 34px;
  }

  .search__input {
    padding: 0 5px;
    height: 36px;
    line-height: 36px;
  }

  .search__inputs {
    width: calc(100% - 110px);
  }

  .timeline {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;

    &__line {
      display: none;
    }

    &__item {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      margin: 2%;
      width: 46%;
      background: #f4f4f4;
    }

    &__icon {
      margin-bottom: 15px;
      width: 36px;
    }

    .info-card {
      height: auto;
    }
  }

  .steps-wrapper {
    justify-content: center;
  }

  .step-card {
    margin: 2%;
    width: 29%;
  }

  .steps-wrapper__line {
    display: none;
  }

  .mt75 {
    margin-top: 30px;
  }

  .road__item {
    &--1 {
      top: 11%;
      left: 4%;
    }

    &--2 {
      top: 34%;
      right: 4%;
    }

    &--3 {
      top: 57%;
      left: 4%;
    }

    &--4 {
      right: 4%;
      bottom: 9%;
    }
  }

  .road__content {
    width: 200px;
  }

  .road__number {
    width: 160px;
  }

  .road__item {
    width: 380px;
  }

  .network {
    flex-wrap: wrap;
    margin-top: 30px;

    &__line {
      display: none;
    }
  }

  .network__item {
    margin: 2% 2% 6%;
    width: 46%;
  }

  .banner--content {
    padding-bottom: 200px;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
  }

  .writer {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .writer__left {
    margin-bottom: 15px;
    width: 100%;
  }

  .writer__time {
    width: auto;
    text-align: left;
  }

  .writer__social {
    margin-top: 6px;
    margin-left: 0;
    width: 100%;

    a {
      margin-right: 6px;
      margin-left: 0;
    }
  }

  .banner--default{
    width: 100%;
    padding: 50px 0;
  }

  .story--reference .story__content{
    padding: 15px;
  }

  .container-banner{
    width: 100%;
  }

  .about-number__icon{
    width: 80px;
    height: 80px;
    padding: 10px;
  }

  .about-number{
    padding: 15px;
  }
}
