@media (max-width:1400px) {
  html {
    font-size: 90%;
  }

  .wrapper {
    padding: 15px;
  }

  .hero__img {
    right: -15px;
  }

  .container-left {
    width: calc(100% + 15px);
  }

  .header {
    margin-top: 15px;
    padding: 0 15px;
    width: calc(100% - 30px);
    height: 72px;

    .btn {
      padding: 0.475rem 0.975rem;
      font-size: 0.9rem;
    }

    &--fixed {
      width: 100%;
    }
  }

  .menu__link {
    font-size: 0.9rem;
  }

  .footer-logo,
  .logo {
    width: 130px;
  }

  .hero {
    margin-top: -87px;

    &__text {
      padding-right: 20%;
      font-size: 1.3rem;
    }
  }

  .underline {
    &:after {
      bottom: -4px;
      height: 3px;
    }
  }

  .mt150 {
    margin-top: 120px;
  }

  .mb150 {
    margin-bottom: 120px;
  }

  .home-text {
    padding-top: 90px;
    padding-bottom: 60px;
  }

  .comment__quote {
    top: -15px;
    left: -15px;
    width: 60px !important;
  }

  .comment__name {
    font-size: 1.3rem;
  }

  .comment__text {
    font-size: 1.1rem;
  }

  .social__link svg {
    width: 24px;
    height: 24px;
  }

  .footer {
    padding-top: 150px;
    padding-bottom: 40px;
  }

  .number-info__number{
    height: 110px;
  }
  .timeline__item{
    &--1{
      top: 2%;
    }

    &--2{
      top: 14%;
    }

    &--3{
      top: 2%;
    }

    &--4{
      top: -2%
    }
  }

  .road__item{
    &--1 {
      top: 12%;
      left:15%;
    }

    &--2 {
      top: 34%;
      right: 14%;
    }

    &--3 {
      top: 58%;
      left: 14%;
    }

    &--4 {
      bottom: 10%;
      right: 15%;
    }
  }
}
