@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'swiper', 'typography', 'buttons';

.btn-mobil,
.mobil {
  display: none;
}

.arama-bg,
.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $renkss;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.arka-active {
  display: block;
}

.swiper-slide {
  img {
    width: 100%;
  }
}

.wrapper {
  padding: 30px;

  &--home {}
}

.header {
  @extend .flex;
  @extend .efekt;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 30px;
  width: calc(100% - 60px);
  height: 112px;
  border-radius: 15px;
  background: rgba(#ffffff, 0);

  &__right {
    @extend .flex;
  }

  &__buttons {
    margin-left: 30px;
  }

  &--page {
    background: #fff4ec;
  }

  &--fixed {
    top: 0;
    width: 100%;
    border-radius: 0;
    background: rgba(#FFEADA, .6);
    backdrop-filter:blur(16px);
  }
}

.logo {
  position: relative;
  top: 4px;
  left: -10px;
}

.menu {
  &__list {
    @extend .flex;
  }

  &__item {
    position: relative;
    margin-left: 30px;

    &.has-sub {
      &:hover {
        & > a {
          color: $renk_turuncu;
        }

        .dropdown {
          display: block;
        }
      }
    }
  }

  &__link {
    @include efekt(color);
    display: inline-block;
    color: #333;
    text-decoration: none;
    font-weight: 500;

    &.active,
    &:hover {
      color: $renk_turuncu;
    }
  }
}

.dropdown {
  @extend .bxsh;
  @extend .efekt;
  position: absolute;
  top: 42px;
  left: 0;
  display: none;
  padding: 15px 0;
  width: 256px;
  border-radius: 10px;
  background: #fff;
  animation: fadeIn 0.5s;

  &:before {
    position: absolute;
    top: -8px;
    left: 15px;
    width: 0;
    height: 0;
    border-width: 0 15px 15px 15px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    content: '';
  }

  &:after {
    position: absolute;
    top: -24px;
    left: 0;
    width: 55%;
    height: 40px;
    background: transparent;
    content: '';
  }

  &__item {}

  &__link {
    @include efekt(background);
    display: block;
    padding: 6px 30px;
    color: #292929;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;

    &:hover {
      background: #F2F2F2;
    }
  }
}

.hero {
  position: relative;
  margin-top: -142px;
  padding-top: 240px;
  height: 90vh;
  border-radius: 15px;
  background-color: #fff4ec;
  background-image: url("../../dist/img/slider-bg.svg");
  background-position: left -120px bottom -120px;
  background-size: contain;
  background-repeat: no-repeat;

  &__content {
    padding-top: 1%;
    padding-left: 5%;
    width: 60%;
  }

  &__small {
    color: $renk_turuncu;
    font-weight: 700;
    font-size: 0.9rem;
  }

  &__title {
    margin-top: 10px;
    color: #333;
    font-weight: 500;
    font-size: 3vw;

    .fw300 {
      display: block;
      color: #000;
    }
  }

  &__text {
    display: block;
    margin-top: 10px;
    margin-bottom: 40px;
    padding-right: 30%;
    color: #666;
    font-size: 1.4rem;
  }

  &__img {
    position: absolute;
    right: -30px;
    bottom: -14%;
    width: 38%;

    img {
      width: 100%;
    }
  }
}

.underline {
  position: relative;
  text-decoration: none;

  &:after {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 6px;
    background: $renk_turuncu;
    content: '';
  }
}

.reference {
  position: relative;
  border: 1px solid $renk_line;
  border-radius: 12px;

  img {
    object-fit: contain;
  }
}

.text {
  color: $renk_yazi;
  line-height: 1.7;

  &--big {
    font-size: 1.2rem;
  }

  &--bigger {
    font-size: 1.3rem;
  }

  &--small {
    font-size: 0.9rem;
  }

  p {
    display: block;
    margin-bottom: 30px;
  }
}

.custom-nav {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--orange {
    .custom-button {
      &:hover {
        background: $renk_turuncu_dk;
      }
    }
  }
}

.custom-button {
  @extend .efekt;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: $renk_turuncu_lt;
  color: $renk_turuncu;
  text-decoration: none;

  &:last-child {
    margin-left: 4px;
  }

  &:hover {
    background: $renk_turuncu;
    color: #fff;
  }

  &--big {
    width: 70px;
    height: 70px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.slider-title {
  @extend .flex;

  .title {
    margin-bottom: 0.5rem;
  }

  &__text {
    width: 80%;
  }
}

.container-left {
  width: calc(100% + 30px);
}

.flex-title {
  @extend .flex;
  align-items: flex-end;

  .title {
    margin-bottom: 0.5rem;
  }

  &__text {
    width: calc(100% - 330px);
  }
}

.service {
  @include efekt(border-color);
  padding: 30px;
  border: 1px solid #F4EFEF;
  border-radius: 10px;

  &__link {
    display: block;
    text-decoration: none;
  }

  &__icon {
    margin-bottom: 50px;
    width: 64px;
    height: 64px;
  }

  &__title {
    @include efekt(color);
    display: block;
    margin-bottom: 20px;
    color: #000;
    font-weight: 600;
    font-size: 1.5rem;
  }

  &__arrow {
    @include efekt(margin-left);
    margin-left: 0;

    path {
      @include efekt(fill);
    }
  }

  &__text {
    display: block;
    margin-bottom: 30px;
    color: #525659;
    font-size: 0.9rem;
  }

  &.active,
  &:hover {
    border-color: $renk_turuncu;

    .service__title {
      color: $renk_turuncu;
    }

    .service__arrow {
      margin-left: 15px;

      path {
        fill: $renk_turuncu;
      }
    }
  }

  &--small {
    .service__title {
      margin-bottom: 5px;
      font-size: 1.1rem;
    }
  }
}

.home-text {
  padding-top: 120px;
  padding-bottom: 90px;
  border-radius: 15px;
  background: #FFF5ED;

  &__img {
    img {
      width: 100%;
      border-radius: 15px;
    }
  }

  &__article {
    padding-left: 30px;
  }
}

.story {
  position: relative;
  height: 100%;
  border: 1px solid $renkss;
  border-radius: 10px;

  &__link {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &__img {
    position: relative;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__content {
    position: relative;
    padding: 30px 30px 80px;
  }

  &__title {
    @include efekt(color);
    display: block;
    margin-bottom: 10px;
    color: #666;
    font-size: 1.2rem;
  }

  &__text {
    color: #666;
  }

  &__arrow {
    @extend .efekt;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;
    color: #666;
    font-weight: 600;
  }

  &:hover {
    .story__title {
      color: $renk_turuncu;
    }

    .story__arrow {
      left: 15px;
      color: #000;
    }
  }

  &--reference {
    .story__title {
      margin-bottom: 15px;
      color: #222;
      font-size: 1.2rem;

      small {
        display: block;
        font-weight: 400;
        font-size: 80%;
      }
    }

    .story__content {
      padding: 20px;
    }

    .story__text {
      color: #666;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}

.home-comments-wrapper {
  position: relative;
  padding: 30px;

  &:before {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 55%;
    border-radius: 15px;
    background: #f7f7f7;
    content: '';
  }

  .home-comments,
  .home-text {
    position: relative;
    z-index: 2;
  }
}

.comment {
  position: relative;
  padding: 30px;
  border: 1px solid #F4EFEF;
  background: rgba(#ffffff, 0.8);
  text-align: center;

  &__quote {
    position: absolute;
    top: -30px;
    left: -30px;
    width: 105px !important;
  }

  &__profile {
    img {
      width: 100px !important;
      height: 100px;
      border-radius: 50%;
      box-shadow: 0 5px 18px rgba(102, 102, 102, 0.25);
    }
  }

  &__text {
    margin-top: 50px;
    margin-bottom: 70px;
    color: #333;
    font-size: 1.2rem;
    line-height: 1.8;
  }

  &__name {
    display: block;
    text-align: center;
    font-size: 1.5rem;

    small {
      display: block;
      color: #b3b3b3;
      font-weight: 400;
      font-size: 1rem;
    }
  }
}

.comments-slider {
  padding-top: 30px;
  padding-left: 30px;
}

.faq {
  &__title {
    @extend .flex;
    @include efekt(color);
    padding: 15px 0;
    border-bottom: 1px solid #f1f1f1;
    font-weight: 500;
    cursor: pointer;

    .bi {
      @include efekt(transform);
    }

    &.active,
    &:hover {
      color: $renk_turuncu;
    }

    &.active {
      .bi {
        transform: rotate(-180deg);
      }
    }
  }

  &__answer {
    @extend .text;
    display: none;
    padding: 15px 0;
  }
}

.home-newsletter {
  padding: 70px 0;
  background: #FF8400;
  color: #fff;
  text-align: center;
}

.newsletter-title {
  .title {
    font-weight: 400;
  }

  .text {
    padding: 0 18%;
    color: #fff;
  }
}

.newsletter {
  @extend .flex;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 560px;

  &__input {
    @extend .efekt;
    display: inline-block;
    padding: 0 15px;
    width: calc(100% - 180px);
    height: 50px;
    outline: none;
    border: 2px solid transparent;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(20, 50, 40, 0.24);
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 50px;

    &:focus,
    &:hover {
      box-shadow: 0 0 0 4px $renksb-4;
    }
  }

  &__btn {
    @extend .efekt;
    width: 170px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 10px;
    background: none;
    color: #fff;
    font-weight: 600;
    line-height: 48px;
    cursor: pointer;

    &:hover {
      background: $renksb;
      box-shadow: 0 0 0 4px $renksb-4;
    }
  }

  &__small {
    color: rgba(#ffffff, 0.7);
    font-size: 0.9rem;
  }
}

.footer {
  padding-top: 200px;
  padding-bottom: 80px;
  background-color: #262626;
  background-image: url("../../dist/img/footer-bg.svg");
  background-position: left -90px bottom -260px;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;

  &__top {}

  &__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__bottom {
    @extend .flex;
    padding-top: 70px;
    padding-bottom: 50px;
  }
}

.footer-content {
  &__box {
    font-weight: 300;
  }

  &__title {
    display: block;
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 0.9rem;
  }

  a {
    @include efekt(color);
    color: #fff;
    text-decoration: none;

    &:Hover {
      color: $renk_turuncu;
    }
  }
}

.footer-logo {
  position: relative;
  left: -12px;
  display: inline-block;
}

.footer-menu {
  &__title {
    display: block;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 1.2rem;
  }

  &__list {}

  &__item {
    margin-bottom: 16px;
  }

  &__link {
    @include efekt(color);
    color: #C9C9C9;
    text-decoration: none;

    &:Hover {
      color: $renk_turuncu;
    }
  }
}

.copyright {
  &__text {
    width: 300px;
    color: #A0A0A0;
    font-size: 0.9rem;
  }
}

.minisoft {}

.social {
  &__link {
    @include efekt(color);
    display: inline-block;
    margin-right: 20px;
    color: #fff;
    text-decoration: none;

    svg {
      width: 40px;
      height: 40px;
    }

    &:hover {
      color: $renk_turuncu;
    }
  }
}

.footer-links {
  &__list {
    @extend .flex;
  }

  &__item {
    margin-left: 15px;
  }

  &__link {
    @include efekt(color);
    color: #A0A0A0;
    text-decoration: none;
    font-size: 0.9rem;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.banner {
  position: relative;
  margin-bottom: 150px;
  padding: 80px 0;
  border-radius: 15px;
  background-color: #fff5ed;
  background-position: right center;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 5px;
    color: $renk_turuncu;
    font-weight: 500;
    font-size: 1.4rem;

    small {
      display: block;
      font-weight: 400;
      font-size: 0.9rem;
    }
  }

  &--content {
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding-right: 15px;
    padding-bottom: 200px;
    padding-left: 15px;
    max-width: 100%;
    width: calc(100% - 120px);
  }

  &--default {
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding: 80px 15px;
    max-width: 100%;
    width: calc(100% - 120px);
  }

  &--slim {
    .title {
      text-align: left;
      font-size: 1.4rem;
    }

    .text--bigger {
      text-align: left;
      font-size: 1rem;
    }
  }

  &--left {
    padding: 0 15px;

    .title {
      text-align: left;
      font-size: 1.7rem;
    }

    .text--bigger {
      text-align: left;
      font-size: 1.2rem;
    }

    .container {
      @extend .flex;
    }
  }
}

.banner-left-text {
  width: calc(100% - 300px);
}

.banner-left-img {
  width: 270px;
}

.search {
  position: absolute;
  right: 0;
  bottom: -70px;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 100%;
  width: 980px;

  &__flex {
    @extend .flex;
    padding: 10px;
    border: 1px solid $renk_turuncu;
    border-radius: 15px;
    background: #fff;
  }

  &__inputs {
    @extend .flex;
    width: calc(100% - 200px);
  }

  &__input {
    display: block;
    padding: 0 15px;
    width: 48%;
    height: 50px;
    outline: none;
    border: none;
    background: #fff;
    color: #7a7a7a;
    font-weight: 500;
    font-size: 1rem;
    line-height: 48px;

    &::placeholder {
      color: #919191;
    }
  }

  &__divider {
    display: inline-block;
    width: 1px;
    height: 48px;
    background: #dfdfdf;
  }

  &__btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 184px;
    height: 50px;
    font-weight: 400;
    line-height: 48px;
  }

  &__small {
    display: block;
    margin-top: 15px;
    color: #858585;
    text-align: center;
    font-weight: 600;
    font-size: 0.9rem;
  }
}

.info-card {
  padding: 30px;
  height: 100%;
  border-radius: 15px;

  &--orange {
    background: #fff4ec;
  }

  &--gray {
    background: #f8f8f8;
  }

  &--blue {
    background: #e8edf6;
  }

  &--blue-lt {
    background: #ebf2ec;
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.1rem;
  }

  &__list {
    padding-left: 20px;
    list-style-type: disc;
    font-weight: 500;

    li {
      margin-bottom: 2px;
    }
  }
}

.number-info {
  &__row {
    margin-bottom: 100px;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &__img {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  &__article {
    position: relative;
    padding: 0 50px;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__number {
    position: absolute;
    top: -30px;
    left: 15px;
    z-index: 0;
  }

  &__title {
    position: relative;
    z-index: 2;
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 1.3rem;
  }

  .text {
    position: relative;
    z-index: 2;
  }
}

.timeline {
  position: relative;
  height: 450px;

  &__line {
    margin-left: -30px;
    max-width: calc(100% + 60px);
    width: calc(100% + 60px);
  }

  &__item {
    position: absolute;

    &--1 {
      top: 6%;
    }

    &--2 {
      top: 20%;
      left: 28%;
    }

    &--3 {
      top: 4%;
      right: 32%;
    }

    &--4 {
      top: 0;
      right: 4%;
    }
  }

  &__icon {
    margin-bottom: 30px;
  }

  .info-card__title {
    margin-bottom: 10px;
    font-size: 1rem;
  }
}

.page-contact {
  padding: 70px 0 40px;
  background: #f5f5f5;
}

.contact-form {
  padding: 70px;
  border-radius: 15px;
  background: #fff;

  .form-label {
    padding-left: 14px;
  }
}

.form-label {
  display: block;
  margin-bottom: 4px;
  color: #333;
  font-size: 0.9rem;
}

.call-link {
  @extend .efekt;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: #222;
  text-decoration: none;
  font-size: 1.5rem;

  svg {
    width: 44px;
    height: 44px;
  }

  &__icon {
    margin-right: 15px;
  }

  &__text {
    font-weight: 700;

    small {
      display: block;
      color: #767676;
      font-weight: 500;
      font-size: 0.8rem;
    }
  }

  &:hover {
    color: $renk_turuncu;
  }
}

.steps-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__line {
    position: absolute;
    top: 34px;
    width: 100%;
    height: 1px;
    border-bottom: 2px dashed $renkss-2;
  }
}

.step-card {
  position: relative;
  z-index: 2;
  width: 18%;

  &--1 {
    .step-card__title {
      background: #F8F3F0;
    }
  }

  &--2 {
    .step-card__title {
      background: #F0E8E2;
    }
  }

  &--3 {
    .step-card__title {
      background: #FAF5ED;
    }
  }

  &--4 {
    .step-card__title {
      background: #ECECF3;
    }
  }

  &--5 {
    .step-card__title {
      background: #F1F9F7;
    }
  }

  &__title {
    @extend .flex;
    margin-bottom: 15px;
    padding: 12px 12px 12px 16px;
    border-radius: 10px;
    font-weight: 600;
  }

  &__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 10px;
    background: $renksb-4;
    color: #333;
    font-weight: 700;
    font-size: 1.3rem;
  }

  &__list {
    padding-left: 20px;
    list-style-type: disc;

    li {
      margin-bottom: 4px;
      font-weight: 500;
    }
  }
}

.road {
  position: relative;

  &__line {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  &__item {
    @extend .flex;
    position: absolute;
    z-index: 4;
    width: 550px;

    &--1 {
      top: 10%;
      left: 21%;
    }

    &--2 {
      top: 34%;
      right: 21%;
      flex-direction: row-reverse;
    }

    &--3 {
      top: 57%;
      left: 21%;
    }

    &--4 {
      right: 21%;
      bottom: 8%;
      flex-direction: row-reverse;
    }
  }

  &__content {
    padding-right: 15px;
    width: 260px;
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    font-size: 1.4rem;
  }

  &__text {
    color: #646464;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.7;
  }

  &__number {}
}

.network {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 1260px;

  &__line {
    position: absolute;

    &--left {
      top: 22%;
      left: 9%;
      transform: scaleX(-1);
    }

    &--middle {
      right: 0;
      bottom: 44%;
      left: 0;
      margin: 0 auto;
    }

    &--right {
      top: 20%;
      right: 8%;
    }
  }

  &__item {
    position: relative;
    z-index: 4;
    width: 240px;
    text-align: center;

    &--1 {}

    &--2 {
      margin-top: 200px;
    }

    &--3 {
      margin-top: 200px;
    }

    &--4 {}
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    padding: 15px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-size: 1.1rem;
  }

  &__text {
    color: #646464;
  }
}

.container-small {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 100%;
  width: 960px;
}

.content-page {
  position: relative;
  z-index: 2;
  margin-top: -150px;
  padding: 0 60px;

  &__cover {
    margin-bottom: 30px;

    img {
      border-radius: 15px;
      box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
    }
  }
}

.writer {
  @extend .flex;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dfdfdf;

  &__left {
    @extend .flex;
    justify-content: flex-start;
    width: 60%;
  }

  &__img {
    width: 47px;
    height: 47px;

    img {
      border-radius: 100%;
    }
  }

  &__content {
    padding-left: 15px;
    width: calc(100% - 100px);
  }

  &__title {
    @extend .flex;
    justify-content: flex-start;
    margin-bottom: 4px;
  }

  &__social {
    margin-left: 15px;

    a {
      @include efekt(color);
      display: inline-block;
      margin-left: 5px;
      color: #8b8b8b;
      text-decoration: none;

      svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        color: $renk_turuncu;
      }
    }
  }

  &__text {
    color: #8b8b8b;
    font-size: 0.9rem;
  }

  &__time {
    width: 120px;
    color: #000;
    text-align: right;
    font-weight: 500;
  }

  &--small {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;

    .writer__img {
      width: 48px;
      height: 48px;
    }

    .writer__title {
      margin-bottom: 0;
      color: #222;
      font-size: 1rem;
    }

    .writer__text {
      font-size: 0.9rem;
    }

    .writer__time {
      color: #666;
      font-size: 0.9rem;
    }
  }
}

.content-wrapper {
  position: relative;
}

.content-article {
  color: #525659;
  line-height: 1.8;

  h1 {
    display: block;
    color: #222;
    font-size: 2rem;
  }

  h2 {
    display: block;
    color: #222;
    font-size: 1.8rem;
  }

  h3 {
    display: block;
    color: #222;
    font-size: 1.6rem;
  }

  h4 {
    display: block;
    color: #222;
    font-size: 1.5rem;
  }

  h5 {
    display: block;
    color: #222;
    font-size: 1.4rem;
  }

  h6 {
    display: block;
    color: #222;
    font-size: 1.2rem;
  }

  p {
    display: block;
    margin-bottom: 30px;
  }

  a {
    @include efekt(color);
    position: relative;
    display: inline-block;
    color: #000;
    text-decoration: none;
    font-weight: 700;

    &:after {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $renk_turuncu;
      content: '';
    }

    &:hover {
      color: $renk_turuncu;
    }
  }
}

.content-share {
  margin-bottom: 15px;
  padding-bottom: 0;
  border-bottom: 1px solid #dfdfdf;
  // position: absolute;
  // top: 8px;
  // left: -60px;
  // width: 28px;
  a {
    @include efekt(color);
    display: inline-block;
    margin-bottom: 15px;
    margin-left: 5px;
    color: #8b8b8b;
    text-decoration: none;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      color: $renk_turuncu;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tag {
  @extend .efekt;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 6px 10px;
  border-radius: 5px;
  background: #F2F2F2;
  color: #525659;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    background: $renk_turuncu;
    color: #fff;
  }
}

.article-detail {
  &__big {
    padding-left: 30px;
    border-left: 6px solid $renk_turuncu;
    font-size: 1.2rem;

    strong {
      font-weight: 600;
    }

    small {
      display: block;
      color: #808080;
      font-weight: 700;
    }
  }

  &__list {
    margin-top: 30px;
    margin-bottom: 15px;
    padding-left: 20px;
    list-style-type: decimal;

    li {
      margin-bottom: 6px;
    }
  }
}

.other-content {
  padding: 50px 6%;
  border-radius: 15px;
  background: $renk_turuncu;
  color: #fff;
}

.blog-box {
  padding: 20px;
  border-radius: 10px;
  background: #fff;

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &__img {
    margin: -20px -20px 0;

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__title {
    @include efekt(color);
    display: block;
    margin-top: 20px;
    min-height: 92px;
    color: $renk_yazi;
    font-weight: 700;
    font-size: 1.1rem;
  }

  .writer__left {
    width: calc(100% - 120px);
  }

  .writer__content {
    width: calc(100% - 50px);
  }

  &:hover {
    .blog-box__title {
      color: $renk_turuncu;
    }
  }
}

.learn-score {
  padding: 50px 0;
  border-radius: 15px;
  background: #262626;
  color: #fff;
  text-align: center;

  .text {
    color: #bdbdbd;
  }

  &__container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 100%;
    width: 780px;
  }
}

.score-form {
  @extend .flex;
  max-width: 100%;

  &__input {
    @extend .efekt;
    display: inline-block;
    padding: 0 15px;
    width: 32%;
    height: 50px;
    outline: none;
    border: 2px solid transparent;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(20, 50, 40, 0.24);
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 50px;

    &:focus,
    &:hover {
      border-color: $renk_turuncu;
    }
  }

  &__btn {
    @extend .efekt;
    width: 32%;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 10px;
    background: none;
    color: #fff;
    font-weight: 600;
    line-height: 48px;
    cursor: pointer;

    &:hover {
      border-color: $renk_turuncu;
      background: $renk_turuncu;
    }
  }
}

.alg-timeline {
  position: relative;
  padding-left: 110px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    border-left: 2px dashed #ccc;
    content: '';
  }

  &__item {
    position: relative;
    margin-bottom: 50px;

    &:last-child {
      &:after {
        position: absolute;
        bottom: 0;
        left: -115px;
        width: 10px;
        height: calc(100% - 18px);
        background: #fff;
        content: '';
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: -119px;
      z-index: 2;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ccc;
      content: '';
    }
  }

  &__arrow {
    position: absolute;
    top: -11px;
    left: -110px;
    z-index: 0;
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    font-size: 1.2rem;
    line-height: 1;
  }

  &__text {
    display: block;
    margin-bottom: 5px;
  }

  &__time {
    color: #525659;
    font-weight: 600;
    font-size: 0.9rem;
  }
}

.container-banner {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: calc(100% - 120px);
}

.about-number {
  padding: 30px;
  border-radius: 15px;
  background: #fff5ed;
  text-align: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(255, 132, 0, 0.26);
  }

  &__number {
    display: block;
    margin-bottom: 15px;
    color: $renk_turuncu;
    font-weight: 700;
    font-size: 5rem;
    line-height: 1.2;

    small {
      display: block;
      color: $renk_turuncu;
      font-size: 1.1rem;
    }
  }

  &__text {
    color: #5b5b5b;
  }
}

.team {
  text-align: center;

  &__img {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: $renkss-4;
      width: 90%;
      height: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      @include efekt(opacity);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__bottom {
    padding: 15px;
    border-radius: 15px;
    background: #F2F2F2;
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 1.2rem;
  }

  &__role {
    font-weight: 400;
    font-size: 0.9rem;
    display: block;
  }

  &__link {
    color: #fff;
    text-decoration: none;
    @extend .efekt;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid #fff;
    z-index: 3;
    @extend .dikeyAbsolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      border-color: $renk_turuncu;
      background: #fff;
      color: $renk_turuncu;
    }
  }

  &:hover {
    .team__img {
      &:before {
        opacity: 1;
      }
    }

    .team__link {
      opacity: 1;
    }
  }
}

.whats-next-wrapper {
  padding: 70px;
  border-radius: 15px;
  background: #262626;
  color: #fff;

  .text {
    display: block;
    padding: 0 22%;
    color: #fff;
  }
}

.whats-next {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__item {
    padding: 15px 30px;

    &:hover {
      .btn {
        top: 0;
        opacity: 1;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    padding: 10px;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background: $renk_turuncu;
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 1.2rem;
  }

  &__text {
    display: block;
    color: #acacac;
    font-weight: 400;
  }

  &__divider {}

  .btn {
    margin-top: 15px;
    opacity: 0;
    position: relative;
    top: 10px;
    @extend .efekt;
  }
}

.contact {
  &__title {
    display: block;
    font-size: 1.5rem;
  }
}

.contact-box {
  &__title {
    display: block;
    margin-bottom: 10px;
  }

  &__text {
    display: block;
    margin-bottom: 15px;
    color: #76787a;
  }

  &__link {
    @include efekt(color);
    color: #000;
    font-weight: 600;

    &:Hover {
      color: $renk_turuncu;
    }
  }

  .flex {
    max-width: 100%;
    width: 260px;
  }
}

.contact-link {
  @include efekt(color);
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;

  svg {
    @include efekt(color);
    margin-right: 6px;
    color: #8397A6;
  }

  strong {
    @include efekt(color);
    display: inline-block;
    margin-right: 10px;
    width: 60px;
    color: #222;
    font-weight: 600;
  }

  span {
    @include efekt(color);
    color: #76787a;
  }

  &:hover {
    span,
    strong,
    svg {
      color: $renk_turuncu;
    }
  }
}

.service-tabs {
  .tab-panel {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid $renk_turuncu;
  }

  .home-text__article {
    padding-left: 0;
    padding-right: 30px;
  }
}

.swiper-slide-active {
  .service {
    border-color: $renk_turuncu;

    .service__title {
      color: $renk_turuncu;
    }

    .service__arrow {
      margin-left: 15px;

      path {
        fill: $renk_turuncu;
      }
    }
  }
}

.up {
  text-align: center;
  line-height: 40px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: $renk_turuncu;
  color: #fff;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 40;
  display: none;

  svg {
    width: 20px;
    height: 40px;
  }

  &:hover {
    background: $renk_turuncu_dk;
  }
}

.map {
  iframe {
    vertical-align: middle;
  }
}
@import 'style1600','style1400','style1200', 'style992', 'mobil-nav', 'style768', 'style576', 'style480';
