$renk_turuncu: #FF8400;
$renk_turuncu_dk: #f17400;
$renk_turuncu_lt: #fff4ec;
$renk_mavi: #00A0E3;
$renk_koyumavi: #0171A0;
$renk_gri: #b3b3b3;
$renk_yazi: #222;
$renk_cizgi: #E0E0E0;
$renk_acik: #F2F5F9;
$renk_line: #FFF3E5;
$renkss: rgba(0, 0, 0, .1);
$renkss-2: rgba(0, 0, 0, .2);
$renkss-3: rgba(0, 0, 0, .3);
$renkss-4: rgba(0, 0, 0, .4);
$renksb: rgba(255, 255, 255, .1);
$renksb-2: rgba(255, 255, 255, .2);
$renksb-3: rgba(255, 255, 255, .3);
$renksb-4: rgba(255, 255, 255, .4);
$renksb-8: rgba(255, 255, 255, .8);
